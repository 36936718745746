import { createGlobalStyle } from "styled-components"

import left from "../images/go-burguer-arrow-left.png"
import right from "../images/go-burguer-arrow-right.png"

const GlobalStyles = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */

  @import url('https://fonts.googleapis.com/css?family=Arvo:400,700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
  
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  * {
    box-sizing: border-box;
  }
  body {
    line-height: 1;
    font-size: 100%;
    font-family: Roboto;
  }
  img {
    display: block;
  	width: 100%;
  	height: auto;
  }

  .ReactModal__Content--after-open { 
  max-width: 380px;
    top: 100px !important;
    bottom: auto !important;
    overflow: initial !important;
        left: 50% !important;
    transform:  translateX(-50%);
    padding: 0 !important;
        box-shadow: 0px 0px 15px;
  }

  .sliderGB .slick-prev{
    z-index: 1;
    left: 25px;
    height: auto;
    transform: translate(0, -100%);
}

.sliderGB .slick-next{
    right: 25px;
    height: auto;
    transform: translate(0, -100%);
}

.sliderGB .slick-dots {
  bottom: 15px
}

 .sliderGB .slick-prev::before {
    content: url(${left});
}

.sliderGB .slick-next::before {
    content: url(${right});
}
`
export default GlobalStyles
